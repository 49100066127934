// source: protobuf/order_transporter_direction_note/order_transporter_direction_note.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var protobuf_validate_validate_pb = require('../../protobuf/validate/validate_pb.js');
goog.object.extend(proto, protobuf_validate_validate_pb);
var protobuf_filter_counters_pb = require('../../protobuf/filter/counters_pb.js');
goog.object.extend(proto, protobuf_filter_counters_pb);
var protobuf_filter_export_pb = require('../../protobuf/filter/export_pb.js');
goog.object.extend(proto, protobuf_filter_export_pb);
var protobuf_filter_request_pb = require('../../protobuf/filter/request_pb.js');
goog.object.extend(proto, protobuf_filter_request_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest', null, global);
goog.exportSymbol('proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest', null, global);
goog.exportSymbol('proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesResponse', null, global);
goog.exportSymbol('proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesRequest', null, global);
goog.exportSymbol('proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesResponse', null, global);
goog.exportSymbol('proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest', null, global);
goog.exportSymbol('proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesResponse', null, global);
goog.exportSymbol('proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest', null, global);
goog.exportSymbol('proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse', null, global);
goog.exportSymbol('proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse', null, global);
goog.exportSymbol('proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest.repeatedFields_, null);
};
goog.inherits(proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest.displayName = 'proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesResponse.displayName = 'proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest.displayName = 'proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.displayName = 'proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest.displayName = 'proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest.repeatedFields_, null);
};
goog.inherits(proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest.displayName = 'proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesResponse.displayName = 'proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest.displayName = 'proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse.repeatedFields_, null);
};
goog.inherits(proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse.displayName = 'proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesRequest.displayName = 'proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesResponse.displayName = 'proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest}
 */
proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest;
  return proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest}
 */
proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest} returns this
 */
proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest} returns this
 */
proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest} returns this
 */
proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesResponse}
 */
proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesResponse;
  return proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesResponse}
 */
proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction_note.DeleteOrderTransporterDirectionNotesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transporterDirectionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest}
 */
proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest;
  return proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest}
 */
proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransporterDirectionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransporterDirectionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest} returns this
 */
proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string transporter_direction_id = 2;
 * @return {string}
 */
proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest.prototype.getTransporterDirectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest} returns this
 */
proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest.prototype.setTransporterDirectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 4;
 * @return {string}
 */
proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest} returns this
 */
proto.order_transporter_direction_note.CreateOrderTransporterDirectionNoteRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    transporterDirectionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    message: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 8, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse}
 */
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse;
  return proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse}
 */
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransporterDirectionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTransporterDirectionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse} returns this
 */
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string order_id = 2;
 * @return {string}
 */
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse} returns this
 */
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string transporter_direction_id = 3;
 * @return {string}
 */
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.prototype.getTransporterDirectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse} returns this
 */
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.prototype.setTransporterDirectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string message = 4;
 * @return {string}
 */
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse} returns this
 */
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse} returns this
*/
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse} returns this
 */
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse} returns this
*/
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse} returns this
 */
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string created_by_id = 8;
 * @return {string}
 */
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse} returns this
 */
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string updated_by_id = 9;
 * @return {string}
 */
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse} returns this
 */
proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transporterDirectionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    message: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest}
 */
proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest;
  return proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest}
 */
proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransporterDirectionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransporterDirectionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest} returns this
 */
proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string transporter_direction_id = 2;
 * @return {string}
 */
proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest.prototype.getTransporterDirectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest} returns this
 */
proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest.prototype.setTransporterDirectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest} returns this
 */
proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string message = 4;
 * @return {string}
 */
proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest} returns this
 */
proto.order_transporter_direction_note.UpdateOrderTransporterDirectionNoteRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest}
 */
proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest;
  return proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest}
 */
proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest} returns this
 */
proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest} returns this
 */
proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest} returns this
 */
proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsMap: (f = msg.getItemsMap()) ? f.toObject(includeInstance, proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesResponse}
 */
proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesResponse;
  return proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesResponse}
 */
proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getItemsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.deserializeBinaryFromReader, "", new proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.serializeBinaryToWriter);
  }
};


/**
 * map<string, OrderTransporterDirectionNoteResponse> items = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse>}
 */
proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesResponse.prototype.getItemsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesResponse} returns this
 */
proto.order_transporter_direction_note.GetOrderTransporterDirectionNotesResponse.prototype.clearItemsMap = function() {
  this.getItemsMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transporterDirectionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    filter: (f = msg.getFilter()) && protobuf_filter_request_pb.Request.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest}
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest;
  return proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest}
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransporterDirectionId(value);
      break;
    case 3:
      var value = new protobuf_filter_request_pb.Request;
      reader.readMessage(value,protobuf_filter_request_pb.Request.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransporterDirectionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      protobuf_filter_request_pb.Request.serializeBinaryToWriter
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest} returns this
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string transporter_direction_id = 2;
 * @return {string}
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest.prototype.getTransporterDirectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest} returns this
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest.prototype.setTransporterDirectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional filter.Request filter = 3;
 * @return {?proto.filter.Request}
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest.prototype.getFilter = function() {
  return /** @type{?proto.filter.Request} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_request_pb.Request, 3));
};


/**
 * @param {?proto.filter.Request|undefined} value
 * @return {!proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest} returns this
*/
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest} returns this
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    counters: (f = msg.getCounters()) && protobuf_filter_counters_pb.Counters.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse}
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse;
  return proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse}
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_counters_pb.Counters;
      reader.readMessage(value,protobuf_filter_counters_pb.Counters.deserializeBinaryFromReader);
      msg.setCounters(value);
      break;
    case 2:
      var value = new proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse;
      reader.readMessage(value,proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCounters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_counters_pb.Counters.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.Counters counters = 1;
 * @return {?proto.filter.Counters}
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse.prototype.getCounters = function() {
  return /** @type{?proto.filter.Counters} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_counters_pb.Counters, 1));
};


/**
 * @param {?proto.filter.Counters|undefined} value
 * @return {!proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse} returns this
*/
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse.prototype.setCounters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse} returns this
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse.prototype.clearCounters = function() {
  return this.setCounters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse.prototype.hasCounters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated OrderTransporterDirectionNoteResponse items = 2;
 * @return {!Array<!proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse>}
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse, 2));
};


/**
 * @param {!Array<!proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse>} value
 * @return {!proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse} returns this
*/
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse}
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.order_transporter_direction_note.OrderTransporterDirectionNoteResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse} returns this
 */
proto.order_transporter_direction_note.ListOrderTransporterDirectionNotesResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && protobuf_filter_request_pb.Request.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesRequest}
 */
proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesRequest;
  return proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesRequest}
 */
proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_request_pb.Request;
      reader.readMessage(value,protobuf_filter_request_pb.Request.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_request_pb.Request.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.Request filter = 1;
 * @return {?proto.filter.Request}
 */
proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesRequest.prototype.getFilter = function() {
  return /** @type{?proto.filter.Request} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_request_pb.Request, 1));
};


/**
 * @param {?proto.filter.Request|undefined} value
 * @return {!proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesRequest} returns this
*/
proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesRequest} returns this
 */
proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_export: (f = msg.getExport()) && protobuf_filter_export_pb.ExportResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesResponse}
 */
proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesResponse;
  return proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesResponse}
 */
proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_export_pb.ExportResponse;
      reader.readMessage(value,protobuf_filter_export_pb.ExportResponse.deserializeBinaryFromReader);
      msg.setExport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExport();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_export_pb.ExportResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.ExportResponse export = 1;
 * @return {?proto.filter.ExportResponse}
 */
proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesResponse.prototype.getExport = function() {
  return /** @type{?proto.filter.ExportResponse} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_export_pb.ExportResponse, 1));
};


/**
 * @param {?proto.filter.ExportResponse|undefined} value
 * @return {!proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesResponse} returns this
*/
proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesResponse.prototype.setExport = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesResponse} returns this
 */
proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesResponse.prototype.clearExport = function() {
  return this.setExport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_transporter_direction_note.ExportOrderTransporterDirectionNotesResponse.prototype.hasExport = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.order_transporter_direction_note);
