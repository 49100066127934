/**
 * @fileoverview gRPC-Web generated client stub for order_transporter_direction
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: protobuf/order_transporter_direction/order_transporter_direction.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protobuf_model_merged_pb from '../../protobuf/model/merged_pb';
import * as protobuf_order_transporter_direction_order_transporter_direction_pb from '../../protobuf/order_transporter_direction/order_transporter_direction_pb';


export class OrderTransporterDirectionServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreate = new grpcWeb.MethodDescriptor(
    '/order_transporter_direction.OrderTransporterDirectionService/Create',
    grpcWeb.MethodType.UNARY,
    protobuf_order_transporter_direction_order_transporter_direction_pb.CreateOrderTransporterDirectionRequest,
    protobuf_model_merged_pb.OrderTransporterDirectionResponse,
    (request: protobuf_order_transporter_direction_order_transporter_direction_pb.CreateOrderTransporterDirectionRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.OrderTransporterDirectionResponse.deserializeBinary
  );

  create(
    request: protobuf_order_transporter_direction_order_transporter_direction_pb.CreateOrderTransporterDirectionRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.OrderTransporterDirectionResponse>;

  create(
    request: protobuf_order_transporter_direction_order_transporter_direction_pb.CreateOrderTransporterDirectionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderTransporterDirectionResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.OrderTransporterDirectionResponse>;

  create(
    request: protobuf_order_transporter_direction_order_transporter_direction_pb.CreateOrderTransporterDirectionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderTransporterDirectionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_transporter_direction.OrderTransporterDirectionService/Create',
        request,
        metadata || {},
        this.methodDescriptorCreate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_transporter_direction.OrderTransporterDirectionService/Create',
    request,
    metadata || {},
    this.methodDescriptorCreate);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/order_transporter_direction.OrderTransporterDirectionService/Update',
    grpcWeb.MethodType.UNARY,
    protobuf_order_transporter_direction_order_transporter_direction_pb.UpdateOrderTransporterDirectionRequest,
    protobuf_model_merged_pb.OrderTransporterDirectionResponse,
    (request: protobuf_order_transporter_direction_order_transporter_direction_pb.UpdateOrderTransporterDirectionRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.OrderTransporterDirectionResponse.deserializeBinary
  );

  update(
    request: protobuf_order_transporter_direction_order_transporter_direction_pb.UpdateOrderTransporterDirectionRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.OrderTransporterDirectionResponse>;

  update(
    request: protobuf_order_transporter_direction_order_transporter_direction_pb.UpdateOrderTransporterDirectionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderTransporterDirectionResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.OrderTransporterDirectionResponse>;

  update(
    request: protobuf_order_transporter_direction_order_transporter_direction_pb.UpdateOrderTransporterDirectionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderTransporterDirectionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_transporter_direction.OrderTransporterDirectionService/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_transporter_direction.OrderTransporterDirectionService/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorUpsert = new grpcWeb.MethodDescriptor(
    '/order_transporter_direction.OrderTransporterDirectionService/Upsert',
    grpcWeb.MethodType.UNARY,
    protobuf_order_transporter_direction_order_transporter_direction_pb.UpsertOrderTransporterDirectionRequest,
    protobuf_model_merged_pb.OrderTransporterDirectionResponse,
    (request: protobuf_order_transporter_direction_order_transporter_direction_pb.UpsertOrderTransporterDirectionRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.OrderTransporterDirectionResponse.deserializeBinary
  );

  upsert(
    request: protobuf_order_transporter_direction_order_transporter_direction_pb.UpsertOrderTransporterDirectionRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.OrderTransporterDirectionResponse>;

  upsert(
    request: protobuf_order_transporter_direction_order_transporter_direction_pb.UpsertOrderTransporterDirectionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderTransporterDirectionResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.OrderTransporterDirectionResponse>;

  upsert(
    request: protobuf_order_transporter_direction_order_transporter_direction_pb.UpsertOrderTransporterDirectionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderTransporterDirectionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_transporter_direction.OrderTransporterDirectionService/Upsert',
        request,
        metadata || {},
        this.methodDescriptorUpsert,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_transporter_direction.OrderTransporterDirectionService/Upsert',
    request,
    metadata || {},
    this.methodDescriptorUpsert);
  }

  methodDescriptorUpsertInfo = new grpcWeb.MethodDescriptor(
    '/order_transporter_direction.OrderTransporterDirectionService/UpsertInfo',
    grpcWeb.MethodType.UNARY,
    protobuf_order_transporter_direction_order_transporter_direction_pb.UpsertDealOrdersTransporterDirectionInfoRequest,
    protobuf_model_merged_pb.DealOrdersTransporterDirectionInfoResponse,
    (request: protobuf_order_transporter_direction_order_transporter_direction_pb.UpsertDealOrdersTransporterDirectionInfoRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.DealOrdersTransporterDirectionInfoResponse.deserializeBinary
  );

  upsertInfo(
    request: protobuf_order_transporter_direction_order_transporter_direction_pb.UpsertDealOrdersTransporterDirectionInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.DealOrdersTransporterDirectionInfoResponse>;

  upsertInfo(
    request: protobuf_order_transporter_direction_order_transporter_direction_pb.UpsertDealOrdersTransporterDirectionInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.DealOrdersTransporterDirectionInfoResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.DealOrdersTransporterDirectionInfoResponse>;

  upsertInfo(
    request: protobuf_order_transporter_direction_order_transporter_direction_pb.UpsertDealOrdersTransporterDirectionInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.DealOrdersTransporterDirectionInfoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_transporter_direction.OrderTransporterDirectionService/UpsertInfo',
        request,
        metadata || {},
        this.methodDescriptorUpsertInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_transporter_direction.OrderTransporterDirectionService/UpsertInfo',
    request,
    metadata || {},
    this.methodDescriptorUpsertInfo);
  }

  methodDescriptorUpsertPriceInfo = new grpcWeb.MethodDescriptor(
    '/order_transporter_direction.OrderTransporterDirectionService/UpsertPriceInfo',
    grpcWeb.MethodType.UNARY,
    protobuf_order_transporter_direction_order_transporter_direction_pb.UpsertOrderTransporterDirectionPriceInfoRequest,
    protobuf_model_merged_pb.OrderTransporterDirectionPriceInfoResponse,
    (request: protobuf_order_transporter_direction_order_transporter_direction_pb.UpsertOrderTransporterDirectionPriceInfoRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.OrderTransporterDirectionPriceInfoResponse.deserializeBinary
  );

  upsertPriceInfo(
    request: protobuf_order_transporter_direction_order_transporter_direction_pb.UpsertOrderTransporterDirectionPriceInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.OrderTransporterDirectionPriceInfoResponse>;

  upsertPriceInfo(
    request: protobuf_order_transporter_direction_order_transporter_direction_pb.UpsertOrderTransporterDirectionPriceInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderTransporterDirectionPriceInfoResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.OrderTransporterDirectionPriceInfoResponse>;

  upsertPriceInfo(
    request: protobuf_order_transporter_direction_order_transporter_direction_pb.UpsertOrderTransporterDirectionPriceInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderTransporterDirectionPriceInfoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_transporter_direction.OrderTransporterDirectionService/UpsertPriceInfo',
        request,
        metadata || {},
        this.methodDescriptorUpsertPriceInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_transporter_direction.OrderTransporterDirectionService/UpsertPriceInfo',
    request,
    metadata || {},
    this.methodDescriptorUpsertPriceInfo);
  }

  methodDescriptorGet = new grpcWeb.MethodDescriptor(
    '/order_transporter_direction.OrderTransporterDirectionService/Get',
    grpcWeb.MethodType.UNARY,
    protobuf_order_transporter_direction_order_transporter_direction_pb.GetOrderTransporterDirectionsRequest,
    protobuf_order_transporter_direction_order_transporter_direction_pb.GetOrderTransporterDirectionsResponse,
    (request: protobuf_order_transporter_direction_order_transporter_direction_pb.GetOrderTransporterDirectionsRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_transporter_direction_order_transporter_direction_pb.GetOrderTransporterDirectionsResponse.deserializeBinary
  );

  get(
    request: protobuf_order_transporter_direction_order_transporter_direction_pb.GetOrderTransporterDirectionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_transporter_direction_order_transporter_direction_pb.GetOrderTransporterDirectionsResponse>;

  get(
    request: protobuf_order_transporter_direction_order_transporter_direction_pb.GetOrderTransporterDirectionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_transporter_direction_order_transporter_direction_pb.GetOrderTransporterDirectionsResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_transporter_direction_order_transporter_direction_pb.GetOrderTransporterDirectionsResponse>;

  get(
    request: protobuf_order_transporter_direction_order_transporter_direction_pb.GetOrderTransporterDirectionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_transporter_direction_order_transporter_direction_pb.GetOrderTransporterDirectionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_transporter_direction.OrderTransporterDirectionService/Get',
        request,
        metadata || {},
        this.methodDescriptorGet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_transporter_direction.OrderTransporterDirectionService/Get',
    request,
    metadata || {},
    this.methodDescriptorGet);
  }

  methodDescriptorList = new grpcWeb.MethodDescriptor(
    '/order_transporter_direction.OrderTransporterDirectionService/List',
    grpcWeb.MethodType.UNARY,
    protobuf_order_transporter_direction_order_transporter_direction_pb.ListOrderTransporterDirectionsRequest,
    protobuf_order_transporter_direction_order_transporter_direction_pb.ListOrderTransporterDirectionsResponse,
    (request: protobuf_order_transporter_direction_order_transporter_direction_pb.ListOrderTransporterDirectionsRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_transporter_direction_order_transporter_direction_pb.ListOrderTransporterDirectionsResponse.deserializeBinary
  );

  list(
    request: protobuf_order_transporter_direction_order_transporter_direction_pb.ListOrderTransporterDirectionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_transporter_direction_order_transporter_direction_pb.ListOrderTransporterDirectionsResponse>;

  list(
    request: protobuf_order_transporter_direction_order_transporter_direction_pb.ListOrderTransporterDirectionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_transporter_direction_order_transporter_direction_pb.ListOrderTransporterDirectionsResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_transporter_direction_order_transporter_direction_pb.ListOrderTransporterDirectionsResponse>;

  list(
    request: protobuf_order_transporter_direction_order_transporter_direction_pb.ListOrderTransporterDirectionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_transporter_direction_order_transporter_direction_pb.ListOrderTransporterDirectionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_transporter_direction.OrderTransporterDirectionService/List',
        request,
        metadata || {},
        this.methodDescriptorList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_transporter_direction.OrderTransporterDirectionService/List',
    request,
    metadata || {},
    this.methodDescriptorList);
  }

  methodDescriptorListJoined = new grpcWeb.MethodDescriptor(
    '/order_transporter_direction.OrderTransporterDirectionService/ListJoined',
    grpcWeb.MethodType.UNARY,
    protobuf_order_transporter_direction_order_transporter_direction_pb.ListJoinedOrderTransporterDirectionsRequest,
    protobuf_order_transporter_direction_order_transporter_direction_pb.ListJoinedOrderTransporterDirectionsResponse,
    (request: protobuf_order_transporter_direction_order_transporter_direction_pb.ListJoinedOrderTransporterDirectionsRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_transporter_direction_order_transporter_direction_pb.ListJoinedOrderTransporterDirectionsResponse.deserializeBinary
  );

  listJoined(
    request: protobuf_order_transporter_direction_order_transporter_direction_pb.ListJoinedOrderTransporterDirectionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_transporter_direction_order_transporter_direction_pb.ListJoinedOrderTransporterDirectionsResponse>;

  listJoined(
    request: protobuf_order_transporter_direction_order_transporter_direction_pb.ListJoinedOrderTransporterDirectionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_transporter_direction_order_transporter_direction_pb.ListJoinedOrderTransporterDirectionsResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_transporter_direction_order_transporter_direction_pb.ListJoinedOrderTransporterDirectionsResponse>;

  listJoined(
    request: protobuf_order_transporter_direction_order_transporter_direction_pb.ListJoinedOrderTransporterDirectionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_transporter_direction_order_transporter_direction_pb.ListJoinedOrderTransporterDirectionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_transporter_direction.OrderTransporterDirectionService/ListJoined',
        request,
        metadata || {},
        this.methodDescriptorListJoined,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_transporter_direction.OrderTransporterDirectionService/ListJoined',
    request,
    metadata || {},
    this.methodDescriptorListJoined);
  }

}

